<template>
  <v-card
    v-if="stage"
    class="mb-8"
    :plan-stage-index="index+1"
  >
    <v-card-actions>
      <v-btn icon color="orange" class="mr-2">
        <v-icon>fa fa-bars</v-icon>
      </v-btn>

      <v-btn icon color="red" class="mr-2" @click="$emit('removeStage', index)">
        <v-icon>fa fa-trash</v-icon>
      </v-btn>
    </v-card-actions>

    <v-card-title>
      第{{ index+1 }}期
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12" xl="6" lg="6" md="6">
          <v-text-field
            dense
            outlined
            :label="$t('plan.data.panel_wp')"
            type="number"
            v-model.number="stage.panel_wp"
          ></v-text-field>
        </v-col>

        <v-col cols="12" xl="6" lg="6" md="6">
          <v-text-field
            dense
            outlined
            :label="$t('plan.data.piece')"
            type="number"
            v-model.number="stage.piece"
          ></v-text-field>
        </v-col>

        <v-col cols="12" xl="6" lg="6" md="6">
          <v-text-field
            dense
            outlined
            :label="$t('plan.data.rate')"
            type="number"
            v-model.number="stage.rate"
          ></v-text-field>
        </v-col>

        <v-col cols="12" xl="6" lg="6" md="6"></v-col>

        <v-col cols="12" xl="6" lg="6" md="6">
          <v-text-field
            dense
            outlined
            readonly
            :label="$t('plan.data.capacity')"
            :value="$helper.getCapacityHumans(totalKwp)"
          ></v-text-field>
        </v-col>

        <v-col cols="12" xl="6" lg="6" md="6">
          <v-text-field
            dense
            outlined
            readonly
            :messages="`約${$helper.numberH(yearlyIncome)}`"
            :label="$t('plan.data.yearly_income')"
            :value="$helper.number(yearlyIncome)"
          ></v-text-field>

          <div>{{'plan.data.yearly_income.help'|t}}</div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    value: Object,
    index: Number,
    dailyPerformanceOfLocation: Number,
  },
  data: () => ({
    stage: null,
  }),
  methods: {
    getDataNumber(property) {
      let data = this.stage[property]
      if(!data) return 0
      data = Number(data)
      if(isNaN(data)) return 0
      return data
    },
  },
  computed: {
    totalKwp() {
      if(!this.stage) return 0
      const panelWp = this.getDataNumber('panel_wp')
      const piece = this.getDataNumber('piece')
      if(!panelWp || !piece) return 0
      return (panelWp*piece)/1000
    },
    yearlyIncome() {
      if(!this.totalKwp) return 0
      if(!this.dailyPerformanceOfLocation) return 0
      const rate = this.getDataNumber('rate')
      const result = this.totalKwp * rate * this.dailyPerformanceOfLocation * 365 * 0.905
      return window.eagleLodash.round(result)
    },
  },
  watch: {
    totalKwp: {
      deep: true,
      handler() {
        if(!this.stage) return
        if(this.totalKwp == this.stage.total_kwp) return
        this.$set(this.stage, 'total_kwp', this.totalKwp)
      },
    },
    yearlyIncome: {
      deep: true,
      handler() {
        if(!this.stage) return
        if(this.yearlyIncome == this.stage.yearly_income) return
        this.$set(this.stage, 'yearly_income', this.yearlyIncome)
      },
    },
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.stage = window.eagleLodash.cloneDeep(this.value)
      },
    },
    stage: {
      deep: true,
      handler() {
        if(window.eagleLodash.isEqual(this.value, this.stage)) return
        this.$emit('input', window.eagleLodash.cloneDeep(this.stage))
      },
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
